import React, { FC, memo, useEffect, useState } from "react";
import { ICard, IQuotationLine } from "..";
import "./styles.css";

/**
   * This function parse a number to currency format
   * @param price number
   * @returns string
   */
export const parseCurrency = (price: number): string =>
new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 0,
}).format(price);

interface IProps {
  servicesArray: Array<ICard>;
}

interface IQuotationTotals {
  lines: IQuotationLine[];
  finalPrice: number;
  totalDiscounts: number;
  totalTaxes: number;
}

const emptyQuotation: IQuotationTotals = {
  lines: [],
  finalPrice: 0,
  totalDiscounts: 0,
  totalTaxes: 0
}

const QuotationTotals: FC<IProps> = ({
  servicesArray
}) => {
  const [quotationTotals, setQuotationTotals] = useState<IQuotationTotals>(emptyQuotation)

  useEffect(() => {
    if (servicesArray.length === 0) {
      setQuotationTotals(emptyQuotation);
    } else {
      const lines = servicesArray
        .filter((card: ICard) => card.quotation && card.quotation.isApproved === true)
        .reduce((prev: IQuotationLine[], current: ICard) => {
        if (current.quotation) {
          return [...prev, ...current.quotation.lines]
        } else {
          return [
            ...prev,
            {
              id: 0,
              label: current.label,
              description: "",
              pricePerUnit: 0,
              numberOfUnits: 1,
              discount: 0,
              amountToDiscount: 0,
              lineTotal: 0
            }
          ]
        }
      }, []);
      const finalPrice = lines.reduce((prev, current) => {
        return prev + current.lineTotal
      }, 0);
      const totalDiscounts = lines.reduce((prev, current) => {
        return prev + (current.discount / 100) * (current.pricePerUnit)
      }, 0);
      const totalTaxes = finalPrice*0.16;
      setQuotationTotals({
        lines,
        finalPrice,
        totalDiscounts,
        totalTaxes
      })
    }
  }, [servicesArray]);
  
  if (quotationTotals.lines.length === 0) return null;

  return (
    <section className="content-section quotation-totals">
      <h2 className="title">Cotización</h2>
      <div className="details-container">
        {quotationTotals.lines.map((line, index) => (
          <div className="detail-line" key={`line-${index}`}>
            <p className="description">{`${index + 1} - ${line.label}`}</p>
            <p className="value">{parseCurrency(line.lineTotal)}</p>
          </div>
        ))}
        <div className="detail-line">
          <p className="description">Descuentos aplicados</p>
          <p className="value">{parseCurrency(quotationTotals.totalDiscounts)}</p>
        </div>
        <div className="detail-line">
          <p className="description">IVA</p>
          <p className="value">{parseCurrency(quotationTotals.totalTaxes)}</p>
        </div>
        
      </div>
      <div className="total-container">
        <p className="description">Total (IVA incluído si aplica)</p>
        <p className="value">{parseCurrency(quotationTotals.finalPrice)}</p>
      </div>
    </section>
  )
}

export default memo(QuotationTotals);
