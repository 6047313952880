import React, { useState, useEffect, memo, FC, useCallback } from "react";
import { Input, Upload } from "antd";
import { RcFile, UploadProps as TUploadProps } from "antd/es/upload/interface";
import moment from "moment";
import {
  PictureOutlined,
  VideoCameraOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import {
  WorkshopService,
  WorkshopServiceTier,
  WorkshopServiceType,
} from "shared/src/data-models/Workshop";
import { useSelector } from "react-redux";
import { getUser } from "../../../../../../stores/selectors";
import CkEditIcon from "../../../../../../assets/SvgIcons/CkEditIcon";
import CkWarningIcon from "../../../../../../assets/SvgIcons/CkWarningIcon";
import CkDangerIcon from "../../../../../../assets/SvgIcons/CkDangerIcon";
import { ICard, ISelectedFile, TRisk } from "..";
import { CkButton, CkInputNumber, CkMessage } from "../../../../../../CkUI";
import { IPackageCategory, IServiceCategory } from "shared";
import { tierCodeToDesc } from "../../../../../Utilities";
import AudioModal from "../AudioModal";
import LocaleAttachmentsMap from "../LocaleAttachmentsMap";
import { parseCurrency } from "../QuotationTotals";
import "./style.css";

const { TextArea } = Input;

const srvOptions: {
  value: WorkshopServiceType;
  label: string;
}[] = [
  {
    value: "MAN",
    label: "Mantenimiento",
  },
  {
    value: "REP",
    label: "Reparación",
  },
];

const riskOptions = [
  {
    value: "HIGH",
    label: "Crítico",
    icon: <CkDangerIcon />,
  },
  {
    value: "MEDIUM",
    label: "Medio",
    icon: <CkWarningIcon />,
  },
  {
    value: "LOW",
    label: "Bajo",
    icon: null,
  },
];
interface IProps {
  servicesArray: Array<ICard>;
  setServicesArray: Function;
  showForm: boolean;
  setShowForm: Function;
  canEditDiagnose?: boolean;
  availablePackagesCategories: IPackageCategory[];
  availableServicesCategories: IServiceCategory[];
  workshopServices: WorkshopService[];
}

export const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const DiagnosticForm: React.FC<IProps> = ({
  servicesArray,
  setServicesArray,
  showForm,
  setShowForm,
  canEditDiagnose,
  availablePackagesCategories,
  availableServicesCategories,
  workshopServices,
}) => {
  const user = useSelector(getUser);

  const [type, setType] = useState<WorkshopServiceType | undefined>();
  const [selectedServiceCategory, setSelectedServiceCategory] = useState<
    IServiceCategory | undefined
  >();
  const [selectedPackageCategory, setSelectedPackageCategory] = useState<
    IPackageCategory | undefined
  >();
  const [selectedService, setSelectedService] = useState<
    WorkshopService | undefined
  >();
  const [tierSelected, setTierSelected] = useState<
    WorkshopServiceTier | undefined
  >();

  const [risk, setRisk] = useState<TRisk>();
  const [diagnosticMessage, setDiagnosticMessage] = useState<string>("");
  const [pricePerUnit, setPricePerUnit] = useState<number>();
  const [discount, setDiscount] = useState<number>(0);
  const [priceDescription, setPriceDescription] = useState<string>("");
  const [
    customDiagnosticFormatAttachment,
    setCustomDiagnosticFormatAttachment,
  ] = useState<ISelectedFile>();
  const [diagnosticAttachments, setDiagnosticAttachements] = useState<
    ISelectedFile[]
  >([]);
  const [audioVisible, setAudioVisible] = useState<boolean>(false);

  useEffect(() => {
    setPricePerUnit(
      tierSelected !== undefined
        ? tierSelected.servicePackageTypeCodeBasePrice
        : undefined
    );
  }, [tierSelected]);

  useEffect(() => {
    ClearForm();
  }, [showForm]);

  const filterMan = (service: WorkshopService) => {
    return service.workshopServiceType === "MAN";
  };

  const filterRep = (service: WorkshopService) => {
    return service.workshopServiceType === "REP";
  };

  const filterAlreadyAdded = (service: WorkshopService) => {
    const alreadyAdded = servicesArray.find(
      (current) => service.workshopServiceId === current.customServiceId
    );
    return alreadyAdded === undefined;
  };

  /**
   *
   * @returns true if information isn't valid, false otherwise, in order to enable or disable add card button
   */
  const isDisabled = (): boolean => {
    // Validate type
    if (type === undefined) return true;

    // Validate selected service
    if (selectedService === undefined) return true;

    // Validate package tier
    if (type === "MAN" && tierSelected === undefined) return true;

    // Validate diagnose
    if (risk === undefined) return true;

    // Validate quotation
    if (pricePerUnit === undefined || !!pricePerUnit === false) return true;

    return false;
  };

  const AddCard = () => {
    if (type === undefined || risk === undefined || pricePerUnit === undefined || selectedService === undefined)
      return;
    const amountToDiscount = calculateLinePercentage();
    const lineTotal = pricePerUnit - amountToDiscount;
    const cardLabel = [
      selectedService.workshopServiceName,
      ...(tierSelected ? [tierCodeToDesc(tierSelected.servicePackageTypeCode)] : [])
    ].join(" - ");
    const newItem: ICard = {
      isCompleted: true,
      type: type,
      label: cardLabel,
      serviceValue: "",
      packageValue: "",
      packageType: selectedService.workshopServiceType,
      servicePackageSetId: 0,
      isCustomService: true,
      customServiceId: selectedService.workshopServiceId,
      customSeviceName: selectedService.workshopServiceName,
      customServiceType: selectedService.workshopServiceType,
      customServiceTier: tierSelected,
      riskValue: risk,
      workshopStaffValue: user ? user!.id : "",
      requiresDiagnosis: true,
      diagnosticMessage: diagnosticMessage,
      customFormat: customDiagnosticFormatAttachment || undefined,
      attachments: diagnosticAttachments,
      quotation: {
        id: 0,
        isApproved: true,
        lines: [
          {
            id: 0,
            label: cardLabel,
            description: priceDescription,
            pricePerUnit: pricePerUnit,
            numberOfUnits: 1,
            discount: discount,
            amountToDiscount: amountToDiscount,
            lineTotal: lineTotal,
          },
        ],
      },
    };
    setServicesArray((prevState: ICard[]) => [...prevState, newItem]);

    const addedElement = document.querySelectorAll(
      ".container-diagnostic-card"
    );
    if (addedElement.length > 0) {
      addedElement &&
        addedElement[addedElement.length - 1].scrollIntoView({
          behavior: "smooth",
        });
    } else {
      document.querySelector(".diagnostic-form-drawer .header-info") !== null &&
        document
          .querySelector(".diagnostic-form-drawer .header-info")!
          .scrollIntoView({ behavior: "smooth" });
    }

    setShowForm(false);
  };

  const ClearForm = () => {
    setType(undefined);
    setSelectedPackageCategory(undefined);
    setSelectedServiceCategory(undefined);
    setSelectedService(undefined);
    setTierSelected(undefined);
    setRisk(undefined);
    setPricePerUnit(undefined);
    setDiscount(0);
    setPriceDescription("");
    setCustomDiagnosticFormatAttachment(undefined);
    setDiagnosticAttachements([]);
    setDiagnosticMessage("");
  };

  const SelectedChoice: FC<{
    label: string;
    value: string;
    onEdit: Function;
  }> = ({ label, value, onEdit }) => (
    <div className="selected-choice">
      <div>
        <p className="label">{label}</p>
        <p className="value">{value}</p>
      </div>
      <CkButton
        variant="secondary"
        color="mariner"
        icon={<CkEditIcon />}
        onClick={() => onEdit && onEdit()}
      />
    </div>
  );

  const getFileDuration = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadProps: TUploadProps = {
    name: "file",
    showUploadList: false,
    multiple: false,
    accept: "image/*, .pdf, .doc, .docx",
    beforeUpload: async (file: any): Promise<any> => {
      const errorMessage =
        "Solo se soportan archivos png, jpg, jpeg, pdf, doc y docx con un tamaño menor a 20 MB.";
      const isLt20M = file.size / 1024 / 1024 < 20;

      if (
        (!file.type.startsWith("image/") &&
          !file.type.startsWith("application/pdf") &&
          !file.type.startsWith("application/msword") &&
          !file.type.startsWith(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          )) ||
        !isLt20M
      ) {
        CkMessage({ type: "error", text: errorMessage });
        return;
      }

      const preview = await getBase64(file as RcFile);
      setCustomDiagnosticFormatAttachment({
        uid: `${file.name}-${moment().unix()}`,
        name: file.name,
        status: "done",
        file: file,
        preview: preview,
        photoUrl: "",
      });
    },
  };

  const uploadAttachmentsProps = {
    //fileList,
    name: "file",
    showUploadList: false,
    accept: "image/*, video/*, audio/*",
    beforeUpload: async (file: any) => {
      const videoDuration = 15;
      if (diagnosticAttachments?.length >= 4) {
        CkMessage({
          type: "error",
          text: "Solo se permite adjuntar dos archivos.",
        });
        return false;
      }

      if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
        CkMessage({
          type: "error",
          text: `Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta ${videoDuration} segundos y menores a 5 MB.`,
        });
        return false;
      }

      if (file.type.startsWith("video/") || file.type.startsWith("audio/")) {
        const duration = await getFileDuration(file);
        if (
          //@ts-ignore
          parseInt(duration) >= videoDuration + 1 ||
          file.size > 1024 * 1024 * 5
        ) {
          CkMessage({
            type: "error",
            text: `Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta ${videoDuration} segundos y menores a 5 MB.`,
          });
          return false;
        }
      }

      const preview = await getBase64(file as RcFile);
      setDiagnosticAttachements((prevState) => [
        ...prevState,
        {
          uid: `${file.name}-${moment().unix()}`,
          name: file.name,
          status: "done",
          file: file,
          preview: preview,
          photoUrl: "",
        },
      ]);

      return file;
    },
    customRequest: ({ onSuccess, onError, file }: any) => {
      // saveFilesLocally(onSuccess, onError, file);
    },
  };

  /**
   * Calculate amount to discount in money
   */
  const calculateLinePercentage = useCallback(() => {
    if (discount <= 0 || pricePerUnit === undefined || pricePerUnit <= 0)
      return 0;
    return (discount / 100) * pricePerUnit;
  }, [discount, pricePerUnit]);

  const calculateLineTotal = useCallback(() => {
    if (pricePerUnit === undefined || pricePerUnit <= 0) return 0;
    return pricePerUnit - (discount <= 0 ? 0 : (discount / 100) * pricePerUnit);
  }, [discount, pricePerUnit]);

  if (canEditDiagnose === undefined || canEditDiagnose === false) return null;

  if (!!showForm === false)
    return (
      <div className="content-section no-background diagnostic-form">
        <CkButton
          variant="secondary"
          color="primary"
          onClick={() => setShowForm(!showForm)}
          className="add-new-card"
        >
          + Agregar mantenimiento o reparación
        </CkButton>
      </div>
    );

  /* Here starts the card */
  return (
    <div className="content-section diagnostic-form">
      <h2 className="title">Agregar mantenimiento o reparación</h2>
      <div className="container-diagnostic-form">
        {/* Choose package or service */}
        {type === undefined && (
          <div className="choose-container choose-type">
            <p className="label">Elige lo que quieres agregar</p>
            <div className="options">
              {srvOptions.map((option, index) => (
                <CkButton
                  key={`choose-type-${index}`}
                  variant="secondary"
                  color="primary"
                  onClick={() => setType(option.value)}
                >
                  {option.label}
                </CkButton>
              ))}
            </div>
          </div>
        )}

        {/* Option choosen */}
        {type && (
          <SelectedChoice
            label="Mantenimiento o reparación"
            value={
              srvOptions.filter((option) => option.value === type)[0].label
            }
            onEdit={() => {
              ClearForm();
            }}
          />
        )}

        {/* Choose maintenance category */}
        {type === "MAN" && selectedPackageCategory === undefined && (
          <div className="choose-container choose-package">
            <p className="label">Elige la categoría mantenimiento</p>
            <div className="options">
              {availablePackagesCategories.map((option, index) => (
                <CkButton
                  key={`choose-package-${index}`}
                  variant="secondary"
                  color="primary"
                  onClick={() => setSelectedPackageCategory(option)}
                >
                  {option.servicePackageDesc}
                </CkButton>
              ))}
            </div>
          </div>
        )}

        {/* Display maintenance category */}
        {type === "MAN" && selectedPackageCategory !== undefined && (
          <SelectedChoice
            label="Categoría"
            value={selectedPackageCategory.servicePackageDesc}
            onEdit={() => {
              setSelectedPackageCategory(undefined);
              setSelectedService(undefined);
              setTierSelected(undefined);
            }}
          />
        )}

        {/* Select maintenance */}
        {type === "MAN" &&
          selectedPackageCategory !== undefined &&
          selectedService === undefined && (
            <div className="choose-container choose-package">
              <p className="label">Elige el mantenimiento</p>
              <div className="options">
                {workshopServices
                  .filter(filterMan)
                  .filter(
                    (service) =>
                      service.servicePackageCode ===
                      selectedPackageCategory.servicePackageCode
                  )
                  .filter(filterAlreadyAdded)
                  .map((option, index) => (
                    <CkButton
                      key={`choose-package-${index}`}
                      variant="secondary"
                      color="primary"
                      onClick={() => setSelectedService(option)}
                    >
                      {option.workshopServiceName}
                    </CkButton>
                  ))}
              </div>
            </div>
          )}

        {/* Display maintenance */}
        {type === "MAN" &&
          selectedPackageCategory !== undefined &&
          selectedService !== undefined && (
            <SelectedChoice
              label="Mantenimiento"
              value={selectedService.workshopServiceName}
              onEdit={() => {
                setSelectedService(undefined);
                setTierSelected(undefined);
              }}
            />
          )}

        {/* Choose tier */}
        {type === "MAN" &&
          selectedPackageCategory !== undefined &&
          selectedService !== undefined &&
          tierSelected === undefined && (
            <div className="choose-container choose-package-tier">
              <p className="label">Elige el tier</p>
              <div className="options">
                {selectedService.serviceTiers.map((option, index) => (
                  <div className="tier-button-container">
                    <CkButton
                      key={`choose-tier-${index}`}
                      variant="secondary"
                      color="primary"
                      onClick={() => setTierSelected(option)}
                    >
                      {tierCodeToDesc(option.servicePackageTypeCode)}
                    </CkButton>
                    <p className="price">
                      {parseCurrency(option.servicePackageTypeCodeBasePrice)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

        {/* Display tier */}
        {type === "MAN" &&
          selectedPackageCategory !== undefined &&
          selectedService !== undefined &&
          tierSelected !== undefined && (
            <SelectedChoice
              label="Tier"
              value={tierCodeToDesc(tierSelected.servicePackageTypeCode)}
              onEdit={() => setTierSelected(undefined)}
            />
          )}

        {type === "REP" && selectedServiceCategory === undefined && (
          <div className="choose-container choose-service-category">
            <p className="label">Elige la categoría reparación</p>
            <div className="options">
              {availableServicesCategories.map((option, index) => (
                <CkButton
                  key={`choose-service-category-${index}`}
                  variant="secondary"
                  color="primary"
                  onClick={() => setSelectedServiceCategory(option)}
                >
                  {option.serviceTypeName}
                </CkButton>
              ))}
            </div>
          </div>
        )}

        {type === "REP" && selectedServiceCategory !== undefined && (
          <SelectedChoice
            label="Categoría de reparación"
            value={selectedServiceCategory.serviceTypeName}
            onEdit={() => {
              setSelectedServiceCategory(undefined)
              setSelectedService(undefined);
            }}
          />
        )}

        {type === "REP" &&
          selectedServiceCategory !== undefined &&
          selectedService === undefined && (
            <div className="choose-container choose-service">
              <p className="label">Elige el servicio de reparación</p>
              <div className="options">
                {workshopServices
                  .filter(filterRep)
                  .filter(
                    (service) =>
                      service.srvcTypeCode ===
                      selectedServiceCategory.serviceTypeCode
                  )
                  .filter(filterAlreadyAdded)
                  .map((service) => (
                    <CkButton
                      key={`choose-service-${service.workshopServiceId}`}
                      variant="secondary"
                      color="primary"
                      onClick={() => {
                        setSelectedService(service);
                      }}
                    >
                      {service.workshopServiceName}
                    </CkButton>
                  ))}
              </div>
            </div>
          )}

        {type === "REP" &&
          selectedServiceCategory !== undefined &&
          selectedService !== undefined && (
            <SelectedChoice
              label="Reparación"
              value={selectedService.workshopServiceName}
              onEdit={() => setSelectedService(undefined)}
            />
          )}

        {((type === "REP" &&
          selectedServiceCategory !== undefined &&
          selectedService !== undefined) ||
          (type === "MAN" &&
            selectedPackageCategory !== undefined &&
            selectedService !== undefined &&
            tierSelected !== undefined)) && (
          <>
            <p className="helper">
              Completa los datos requeridos abajo o adjunta tu propio formato si
              cuentas con uno.
            </p>
            <hr />
            <div className="choose-container second-phase choose-risk">
              <p className="title">Nivel de riesgo</p>
              <div className="options">
                {riskOptions.map((option) => (
                  <CkButton
                    key={`choose-risk-${option.value}`}
                    variant="secondary"
                    className={[
                      `--${option.value.toLowerCase()}`,
                      ...(risk === option.value ? ["selected"] : []),
                    ].join(" ")}
                    onClick={() => {
                      setRisk(option.value);
                    }}
                    icon={option.icon}
                  >
                    <span>{option.label}</span>
                  </CkButton>
                ))}
              </div>
            </div>
            <div className="choose-container second-phase type-diagnostic">
              <p className="title">Diagnóstico</p>
              <p className="label">Describe la situación del vehículo</p>
              <div className="input-container">
                <TextArea
                  style={{ width: "100%" }}
                  value={diagnosticMessage}
                  className="textarea-size"
                  rows={4}
                  placeholder="Describe el diagnóstico"
                  onChange={(e) => setDiagnosticMessage(e.target.value)}
                />
                <div className="attachment-buttons-container">
                  <Upload
                    {...uploadAttachmentsProps}
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                    disabled={diagnosticAttachments.length >= 4}
                  >
                    <PictureOutlined />
                  </Upload>

                  <Upload
                    {...uploadAttachmentsProps}
                    accept="video/mp4, video/avi, video/flv, video/wmv, video/mov"
                    multiple
                  >
                    <VideoCameraOutlined />
                  </Upload>

                  <AudioOutlined
                    onClick={(e) => {
                      setAudioVisible(true);
                    }}
                  />
                </div>
              </div>
              <LocaleAttachmentsMap
                fileList={diagnosticAttachments}
                setFileList={setDiagnosticAttachements}
                readOnly={false}
              />
            </div>
            <hr />
            <div className="choose-container second-phase quotation">
              <p className="title">Cotización</p>
              <div
                className="quotation-form-container"
                style={{ alignItems: "center" }}
              >
                {(type === "REP" ||
                  (type === "MAN" &&
                    !!tierSelected.servicePackageTypeCodeBasePrice ===
                      false)) && (
                  <div className="form-item price-per-unit">
                    <p className="label">Precio unitario</p>
                    <CkInputNumber
                      variant="rounded"
                      inputProps={{
                        value: pricePerUnit,
                        controls: false,
                        min: 0,
                        addonBefore: "$",
                        onChange: (e) => e !== null && setPricePerUnit(e),
                      }}
                    />
                  </div>
                )}
                {type === "MAN" && tierSelected.servicePackageTypeCodeBasePrice && pricePerUnit && (
                  <div className="form-display price-per-unit">
                    <p className="label">Precio unitario</p>
                    <p className="price">{parseCurrency(pricePerUnit)}</p>
                  </div>
                )}
                <div className="form-item discount">
                  <p className="label">Descuento</p>
                  <CkInputNumber
                    variant="rounded"
                    inputProps={{
                      value: discount,
                      min: 0,
                      max: 100,
                      step: 1,
                      addonAfter: "%",
                      controls: false,
                      onChange: (e) => e !== null && setDiscount(e),
                    }}
                  />
                </div>
                <div className="form-display show-subtotal-discount">
                  <p className="subtotal-discount">
                    {parseCurrency(calculateLinePercentage() * -1)}
                  </p>
                </div>
              </div>
              <div className="quotation-form-container">
                <div className="form-item description">
                  <p className="label">Descripción</p>
                  <TextArea
                    style={{ width: "100%" }}
                    value={priceDescription}
                    className="textarea-size"
                    rows={4}
                    onChange={(e) => setPriceDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="quotation-form-container">
                <div className="form-display subtotal">
                  <p className="label">Subtotal</p>
                  <p className="price">{parseCurrency(calculateLineTotal())}</p>
                </div>
              </div>
            </div>
            <hr />
          </>
        )}

        <div className="button-container">
          <CkButton
            variant="primary"
            color="primary"
            disabled={isDisabled()}
            onClick={AddCard}
          >
            Agregar mantenimiento o reparación
          </CkButton>
          <CkButton
            variant="link"
            color="primary"
            onClick={() => setShowForm(false)}
          >
            Cancelar
          </CkButton>
        </div>
      </div>
      <AudioModal
        fileList={diagnosticAttachments}
        setFileList={setDiagnosticAttachements}
        audioVisible={audioVisible}
        setAudioVisible={setAudioVisible}
      />
    </div>
  );
  /* /Here ends the card */
};

export default memo(DiagnosticForm);
