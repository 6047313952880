import React, { FC, memo } from "react";
import {
  Col,
  Button,
  Row,
} from "antd";

import { Appointment } from "shared";
//Images & Icons
import { LeftChevronIcon } from "../../../../../../assets/SvgIcons";

import moment from "moment";

import "./style.css";

interface IProps {
  appointmentTracking: any;
  appointmentInfo: Appointment;
  currentDate: moment.Moment | undefined;
  onClose: Function;
}

const HeaderInfo: FC<IProps> = ({
  appointmentTracking,
  appointmentInfo,
  currentDate,
  onClose,
}) => {
  /** Components */
  const VehicleCard = () => {
    if (appointmentInfo?.vehicle === undefined) return null;
    return (
      <div className="field">
        <p className="description">Vehículo</p>
        {appointmentInfo?.vehicle?.nickname && (
          <p className="value semibold">{appointmentInfo.vehicle.nickname}</p>
        )}
        <p className="value">
          {appointmentInfo?.vehicle?.brandName &&
            appointmentInfo.vehicle.brandName}{" "}
          {appointmentInfo?.vehicle?.modelName &&
            appointmentInfo.vehicle.modelName}
        </p>
      </div>
    );
  };

  const FolioDisplay = () => (
    <div className="field">
      <p className="description">Orden de servicio No.</p>
      <p className="semibold value">
        {appointmentTracking &&
          appointmentTracking?.invoiceNo &&
          appointmentTracking?.invoiceNo}{" "}
        -{" "}
        {appointmentTracking &&
          appointmentTracking?.version &&
          appointmentTracking?.version}
      </p>
    </div>
  );

  const CloseButton = () => (
    <Button
      className="close-btn"
      type="ghost"
      onClick={() => onClose()}
      icon={<LeftChevronIcon />}
    />
  );

  if (appointmentInfo === undefined || appointmentInfo === null) return null;

  return (
    <section className="header-info">
      <div className="drawer-title">
        <CloseButton />
        <h1>Diagnóstico del vehículo</h1>
      </div>
      <Row className="appointment-resume">
        <Col xs={12} lg={6}>
          <VehicleCard />
        </Col>
        <Col xs={12} lg={6}>
          <FolioDisplay />
        </Col>
        <Col xs={12} lg={6}>
          <div className="field">
            <p className="description">Fecha</p>
            <p className="capitalize value">{currentDate?.format('dddd DD/MM/YYYY')}</p>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="field">
            <p className="description">Hora</p>
            <p className="value">{currentDate?.format("HH:mm")}</p>
          </div>
        </Col>
        
      </Row>
    </section>
  );
};

export default memo(HeaderInfo);
