import React, { FC, memo, useContext, useState, useEffect } from "react";
import { Image } from  "antd";
import { useSelector, useDispatch } from "react-redux";

import { getSasToken, getUsers } from "../../../../../../stores";
import { CurrentUser } from "../../../../../../context/UserContext";
import CheckMarkIcon from "../../../../../../assets/SvgIcons/CheckMarkIcon";
import avatar from "../../../../../../assets/Images/avatar-coworker-placeholder.svg";

import { Appointment, RoleCode, User, fetchCoworkers } from "shared";
import { CkIcon } from "../../../../../../CkUI";

import "./styles.css";

const getRoleName = (roleCode: RoleCode | ""): string => {
  if (roleCode === "ADM") return "Administrador";
  if (roleCode === "OWN") return "Dueño";
  if (roleCode === "MEC") return "Mecánico";
  return "";
};

interface IProps {
  appointmentInfo: Appointment;
  selectedUser?: number;
  setSelectedUser: Function;
  canEditDiagnose?: boolean;
}

const TeamMemberSelection: FC<IProps> = ({
  appointmentInfo,
  selectedUser,
  setSelectedUser,
  canEditDiagnose
}) => {
  const dispatch = useDispatch();
  const user = useContext(CurrentUser);
  const coworkers = useSelector(getUsers);
  const sasToken = useSelector(getSasToken);
  const [displayComponent, setDisplayComponent] = useState<boolean>(false);
  
  /**
   * Components
   */
  const UserCard: FC<{ user: User, selected: boolean, key: string }>  = ({ user, selected, key }) => (
    <figure 
      key={key}
      className={[
        "user-card",
        ...(parseInt(user.id) === selectedUser ? ["selected"] : []),
        ...(canEditDiagnose === false ? ["not-clickable"] : [])
      ].join(" ")}
      onClick={() => canEditDiagnose && parseInt(user.id) !== selectedUser && setSelectedUser(parseInt(user.id))}
    >
      <Image
        preview={false}
        src={sasToken && user?.photoUrl ? `${user?.photoUrl}?${sasToken}` : avatar}
        fallback={avatar}
      />
      <figcaption>
        {selected && <CkIcon name="checkmark" />}
        <p>
          {user?.name} {user?.lastName}
        </p>
      </figcaption>
    </figure>
  );

  /**
   * Effects
   */
  useEffect(() => {
    canEditDiagnose && selectedUser === undefined && user && setSelectedUser(parseInt(user.id))
  }, [user, selectedUser, canEditDiagnose]);

  useEffect(() => {
    if (user && appointmentInfo) {
      const userRole = user.userRoles.filter((role) => role.workshopId === appointmentInfo.workshop.id)[0];
      if (userRole && userRole.roleCode !== "MEC") {
        setDisplayComponent(true);
        dispatch(fetchCoworkers(appointmentInfo.workshop.id));
      }
      // setSelectedUser(parseInt(user.id));
    }
  }, [user, appointmentInfo]);

  if (displayComponent === false) return null;

  return (
    <section className="content-section team-member-selection">
      <h2 className="subtitle">Personal del taller que hace el diagnóstico</h2>
      <div className="card-grid">
        {coworkers && coworkers.map((user) => UserCard({
          key: `user-${user.id}`,
          user: user,
          selected: selectedUser === parseInt(user.id)}))}
      </div>
    </section>
  )
}

export default memo(TeamMemberSelection);
