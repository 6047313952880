import React, { FC, useEffect, useState, memo } from "react";
import { Drawer, Spin } from "antd";
import { RcFile } from "antd/es/upload/interface";
import { BlobServiceClient } from "@azure/storage-blob";
import { useSelector } from "react-redux";
import { Appointment, IPackageCategory, IServiceCategory } from "shared";
import {
  WorkshopService,
  WorkHours,
  WorkshopServiceTier,
  WorkshopServiceType,
  WorkshopServiceStatus,
  WorkshopServiceCase,
  WorkshopServiceVehcicleZone,
} from "shared/src/data-models/Workshop";
import { AppointmentAPI } from "../../../../Services/axios";
import { getSasToken } from "../../../../../stores/selectors";
import Compressor from "compressorjs";
import { getUser } from "../../../../../stores/selectors";

import HeaderInfo from "./HeaderInfo";
import DiagnosticForm from "./DiagnosticForm";
import DiagnosticCard from "./DiagnosticCard";
import QuotationTotals from "./QuotationTotals";
import TeamMemberSelection from "./TeamMemberSelection";

import {
  getPackagesCategories,
  getServicesCategories,
  getWorkshops,
  getWorkshopServices,
} from "../../../../../stores";
import { Workshop, Package } from "shared/src/data-models/Workshop";

import moment from "moment";

import { CalendarDeleteIcon } from "../../../../../assets/SvgIcons";
import { ConfirmationModal } from "../../ConfirmationModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import QuotationApprovalModal, {
  TContactChannels,
} from "../QuotationFormModal/QuotationApprovalModal";

import "./styles.css";
import { TQuotationStatus } from "../QuotationFormModal";
import { CkButton, CkMessage } from "../../../../../CkUI";
import { tierCodeToDesc } from "../../../../Utilities";

const extractFileName = (url: string): string => {
  const pathName = new URL(url).pathname;
  const parts = pathName.split("/");
  return decodeURIComponent(parts[parts.length - 1]);
};

export interface StoredQuotationItemUser {
  id: string;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  sex: string | null;
  zipCode: string | null;
  photoUrl: string;
}
export interface StoredQuotationItemFile {
  fileName: string;
  photoUrl: string;
}

export interface StoredQuotationItem {
    customServiceSetId: number;
    riskLevelCode: TRisk;
    details: string;
    diagnosticRequired: boolean;
    responsibleUser: number;
    customFormat: string | null;
    attachments: StoredQuotationItemFile[];
    customService: {
        workshopServiceId: number;
        workshopServiceName: string;
        workshopServiceType: WorkshopServiceType;
        createdDate: string;
        modifiedDate: string;
        workshopServiceStatus: WorkshopServiceStatus;
        isVisibleForEndConsumer: boolean;
        servicePackageCode: string | null;
        servicePackageDesc: string | null;
        srvcTypeCode: string | null
        srvcTypeDsc: string | null
        basePrice: string | null
        serviceComments: string | null,
        serviceTiers: WorkshopServiceTier; // TODO: Check this interface
        serviceCases: WorkshopServiceCase[]; // TODO: Check this interface
        vehicleServiceZones: WorkshopServiceVehcicleZone[] // TODO: Check this interface
        result: number;
    },
    quotation: {
        id: number;
        isQuotationApproved: boolean;
        customServiceLines: IQuotationLine[];
    },
    userCreatedBy: StoredQuotationItemUser,
    userResponsibleBy: StoredQuotationItemUser
}
export interface IQuotationLine {
  id: number;
  label: string;
  description: string;
  pricePerUnit: number;
  numberOfUnits: number;
  discount: number;
  amountToDiscount: number;
  lineTotal: number;
  editableLine?: boolean;
}
export interface IQuotationItem {
  id: number;
  isApproved: boolean;
  lines: IQuotationLine[];
}

export type TRisk = "HIGH" | "MEDIUM" | "LOW";

export const riskOptions: {
  value: TRisk;
  label: string;
}[] = [
  {
    value: "HIGH",
    label: "Crítico",
  },
  {
    value: "MEDIUM",
    label: "Medio",
  },
  {
    value: "LOW",
    label: "Bajo",
  },
];

export interface ISelectedFile {
  uid: string;
  name: string;
  status: string;
  file?: RcFile;
  preview?: string;
  photoUrl?: string;
}

export interface ICard {
  isCompleted: boolean;
  type: WorkshopServiceType;
  label: string;
  serviceValue: string;
  packageValue: string;
  packageType: string;
  servicePackageSetId: number;
  isCustomService: boolean;
  customServiceId: number;
  customSeviceName: string;
  customServiceType: WorkshopServiceType;
  customServiceTier: WorkshopServiceTier | undefined;
  riskValue: TRisk;
  workshopStaffValue: string;
  requiresDiagnosis: boolean;
  diagnosticMessage: string;
  attachments: ISelectedFile[];
  customFormat?: ISelectedFile;
  quotation?: IQuotationItem;
}

export interface IPackTier {
  label: string;
  packageName: string;
  packageCode: string;
  type: string;
  typeDesc: string;
  price: number;
  servicePackageSetId: number;
}
export interface IPackWithTiers {
  packageName: string;
  packageCode: string;
  tiers: IPackTier[];
}

interface ICow {
  // id: string;
  // name: string;
  // lastName: string;
  value: string;
  label: string;
}

interface IServAdded {
  code: string;
}

interface IPackAdded {
  code: string;
  type: string;
}
interface ITrigger {
  trigger: boolean;
  serviceCode: string | undefined;
  packageCode: string | undefined;
  packageType: string | undefined;
}

export interface IFile {
  uid: string;
  name: string;
  status: string;
  file: any;
  fileName?: string;
  photoUrl?: string;
  preview?: string;
}

interface IUploadAttachmentOutput {
  fileName: string;
  photoUrl: string;
}

type TUploadAttachmentOutput = IUploadAttachmentOutput | null;

interface IServiceQuotationPayload {
  Id: number;
  isQuotationApproved: boolean;
  ServicesLines: IQuotationLine[];
}
interface IPackageQuotationPayload {
  Id: number;
  isQuotationApproved: boolean;
  PackagesLines: IQuotationLine[];
}
interface ICustomServiceAdded {
  code: number;
  tier: WorkshopServiceTier | undefined;
  type: WorkshopServiceType;
}
interface ICustomServiceQuotation {
  id: number;
  isQuotationApproved: boolean;
  customServiceLines: IQuotationLine[];
}
interface ICustomServiceQuotationPayload {
  CustomServiceSetId: number;
  CustomService: {
    WorkshopServiceId: number;
  }
  riskLevelCode: string;
  details: string;
  diagnosticRequired: boolean;
  responsibleUser: number;
  customFormat: string;
  attachments: {
    fileName: string;
    photoUrl: string;
  }[];
  Quotation: ICustomServiceQuotation;
  UserCreatedBy: {
    Id: string;
  };
  UserResponsibleBy: {
    Id: string;
  };
}

interface IUploadPayload {
  CustomServices: ICustomServiceQuotationPayload[];
}

interface IProps {
  diagnosticConfirmation: boolean;
  setDiagnosticConfirmation: Function;
  appointmentInfo: Appointment;
  appointmentId: string;
  hours: WorkHours[];
  workshopId: string;
  services: WorkshopService[];
  packages: any[];
  handleConfirm: Function;
  coworkers: Array<any>;
  mode: "DIAG" | "QUOT";
  isCalledFromCalendar?: boolean;
}

const DiagnosticFormDrawer: FC<IProps> = ({
  diagnosticConfirmation,
  setDiagnosticConfirmation,
  appointmentInfo,
  appointmentId,
  hours,
  workshopId,
  services,
  //  packages,
  handleConfirm,
  coworkers,
  mode,
  isCalledFromCalendar,
}) => {
  let blobService: any = null;
  const account = process.env.REACT_APP_BLOB_ACCOUNT as string;
  const containerName = "up-images-container";

  const user = useSelector(getUser);
  const workshops: Workshop[] = useSelector(getWorkshops);
  const packagesCategories = useSelector(getPackagesCategories);
  const servicesCategories = useSelector(getServicesCategories);
  const workshopServices = useSelector(getWorkshopServices);
  const sasToken: string = useSelector(getSasToken);

  /** States */
  const [availablePackagesCategories, setAvailablePackagesCategories] =
    useState<IPackageCategory[]>([]);
  const [availableServicesCategories, setAvailableServicesCategories] =
    useState<IServiceCategory[]>([]);
  const [packages, setPackages] = useState<any[]>();
  const [canEditDiagnose, setCanEditDiagnose] = useState<boolean>();
  const [canEditQuotation, setCanEditQuotation] = useState<boolean>();
  const [servicesArray, setServicesArray] = useState<Array<ICard>>([]);
  const [servicesAdded, setServicesAdded] = useState<Array<IServAdded>>([]);
  const [packagesAdded, setPackagesAdded] = useState<Array<IPackAdded>>([]);
  const [customServiceAdded, setCustomServiceAdded] = useState<
    ICustomServiceAdded[]
  >([]);
  const [packagesWithTiers, setPackagesWithTiers] = useState<IPackWithTiers[]>(
    []
  );
  const [packagesItems, setPackagesItems] = useState<Array<IPackTier>>([]);
  const [coworkersItems, setCoworkersItems] = useState<Array<ICow>>([]);
  const [triggerToClear, setTriggerToClear] = useState<ITrigger>({
    trigger: false,
    serviceCode: undefined,
    packageCode: undefined,
    packageType: undefined,
  });
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [exitModal, setExitModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [completeProcess, setCompleteProcess] = useState<boolean>(false);
  const [confirmCloseModal, setConfirmCloseModal] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(0);

  const [appointmentTracking, setAppointmentTracking] = useState<any>();
  const [currentDate, SetCurrentDate] = useState<moment.Moment>();
  const [containerClient, setContainerClient] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<number>();
  const [showQuotationApprovalModal, setShowQuotationApprovalModal] =
    useState<boolean>(false);

  /** Effects */
  useEffect(() => {
    if (packagesCategories.length > 0 && workshopServices.length > 0) {
      const services = workshopServices.filter(filterMan).filter(filterAlreadyAdded);
      const availableCategories = packagesCategories.filter((category) =>
        services.some(
          (service) =>
            service.servicePackageCode === category.servicePackageCode
        )
      );
      setAvailablePackagesCategories(availableCategories);
    }
  }, [packagesCategories, workshopServices, servicesArray]);

  useEffect(() => {
    if (servicesCategories.length > 0 && workshopServices.length > 0) {
      const services = workshopServices.filter(filterRep).filter(filterAlreadyAdded);
      const availableCategories = servicesCategories.filter((category) =>
        services.some(
          (service) => service.srvcTypeCode === category.serviceTypeCode
        )
      );
      setAvailableServicesCategories(availableCategories);
    }
  }, [servicesCategories, workshopServices, servicesArray]);

  useEffect(() => {
    getCoworkers();
  }, [coworkers]);

  useEffect(() => {
    handleScroll(showForm);
  }, [showForm]);

  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      setContainerClient(blobService.getContainerClient(containerName));
    }
  }, [sasToken]);

  useEffect(() => {
    if (completeProcess) {
      setIsLoading(false);
      setCompleteProcess(false);
      handleConfirm("QUOT");
      CkMessage({
        type: "success",
        text: "Diagnóstico enviado correctamente.",
      });
    }
  }, [completeProcess]);

  useEffect(() => {
    if (diagnosticConfirmation === false) return;
    if (appointmentId === undefined) return;
    AppointmentAPI.getTrackingReception(appointmentId)
      .then((response) => {
        if (response && response.data) {
          console.info("response.data", response.data);
          setAppointmentTracking(response.data);
        } else {
          setAppointmentTracking(undefined);
        }
      })
      .catch((error) => {
        setAppointmentTracking(undefined);
      });
  }, [diagnosticConfirmation, appointmentId]);

  useEffect(() => {
    if (appointmentInfo && appointmentId && !!workshops) {
      getAllPackages();
      getReceptionData();
      setServicesAdded([]);
      setPackagesAdded([]);
    }
  }, [appointmentInfo, appointmentId, workshops]);

  /** Functions */

  const filterMan = (service: WorkshopService) => {
    return service.workshopServiceType === "MAN";
  };

  const filterRep = (service: WorkshopService) => {
    return service.workshopServiceType === "REP";
  };

  const filterAlreadyAdded = (service: WorkshopService) => {
    const alreadyAdded = servicesArray.find(
      (current) => service.workshopServiceId === current.customServiceId
    );
    return alreadyAdded === undefined;
  };

  /**
   * Get reception data
   */
  const getReceptionData = () => {
    clearDrawer();
    AppointmentAPI.getTrackingReception(appointmentId)
      .then((result) => {
        const { data } = result;
        if (data) {
          const {
            appointmentStatus,
            quotationStatus,
          }: {
            appointmentStatus: string;
            quotationStatus: TQuotationStatus | undefined | null;
          } = data;

          console.info("data", data);
          console.info("appointmentStatus", appointmentStatus);
          console.info("quotationStatus", quotationStatus);

          setCanEditDiagnose(appointmentStatus === "RECEP");
          if (appointmentStatus === "RECEP" || appointmentStatus === "DIAG") {
            setCanEditQuotation(true);
          } else if (appointmentStatus === "QUOT") {
            setCanEditQuotation(quotationStatus !== "APPROVED");
          } else {
            setCanEditQuotation(false);
          }

          if (
            data.packagesRequired.length > 0 ||
            data.servicesRequired.length > 0 ||
            data.customServiceRequired.length > 0
          ) {
            setIsReadOnly(true);
            createInitialCardsReadOnly(
              data.packagesRequired,
              data.servicesRequired,
              data.customServiceRequired
            );
          } else {
            setIsReadOnly(false);
            createInitialCards();
          }

          const diagnosticDate =
            appointmentInfo.appointmentHistoryStatus.filter(
              (status: any) => status.statusCode === "DIAG"
            )[0];
          if (diagnosticDate !== undefined) {
            const date = moment(
              diagnosticDate.statusModifiedDate.endsWith("Z")
                ? diagnosticDate.statusModifiedDate
                : `${diagnosticDate.statusModifiedDate}Z`
            );
            SetCurrentDate(date);
          } else {
            SetCurrentDate(moment());
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setDiagnosticConfirmation(false);
      });
  };

  const handleScroll = (toTop: boolean) => {
    const elementCard = document.getElementById("diagnostic-card-center");
    const elementForm = document.getElementById("diagnostic-form-center");

    if (elementCard && toTop == false) {
      elementCard.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "nearest",
      });
    } else if (elementForm && toTop == true) {
      elementForm.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const createInitialCardsReadOnly = (
    receivedPackages: any,
    receivedServices: any,
    customServiceRequired: StoredQuotationItem[]
  ) => {
    if (customServiceRequired.length > 0) {
      
      
      const cardsArray: ICard[] = customServiceRequired.map((service) => {
        console.info("service", service);
        const cardLabel = [
          service.customService.workshopServiceName,
          // TODO: add tier when type === "MAN"
        ].join(" - ");
        const unixDate = moment().unix();  
        const cards : ICard = {
          isCompleted: true,
          type: service.customService.workshopServiceType,
          label: cardLabel,
          serviceValue: "",
          packageValue: "",
          packageType: "",
          servicePackageSetId: 0,
          isCustomService: true,
          customServiceId: service.customService.workshopServiceId,
          customSeviceName: service.customService.workshopServiceName,
          customServiceType: service.customService.workshopServiceType,
          customServiceTier: undefined,
          riskValue: service.riskLevelCode,
          workshopStaffValue: service.userResponsibleBy
            ? service.userResponsibleBy.id
            : "",
          requiresDiagnosis: service.diagnosticRequired,
          diagnosticMessage: service.details,
          attachments: service.attachments
            ? service.attachments.map(
                (attachment: {
                  fileName: string;
                  photoUrl: string;
                }): ISelectedFile => {
                  return {
                    uid: `${unixDate}-${attachment.fileName}`,
                    name: attachment.fileName,
                    status: "success",
                    photoUrl: attachment.photoUrl,
                  };
                }
              )
            : [],
          ...(service.customFormat
            ? {
                customFormat: {
                  uid: `${unixDate}-${extractFileName(service.customFormat)}`,
                  name: extractFileName(service.customFormat),
                  status: "success",
                  photoUrl: service.customFormat,
                },
              }
            : {}),
          ...(service.quotation
            ? {
                quotation: {
                  id: service.quotation.id,
                  isApproved: service.quotation.isQuotationApproved,
                  lines: service.quotation.customServiceLines || [],
                },
              }
            : {
                quotation: {
                  id: 0,
                  isApproved: true,
                  lines: [
                    {
                      id: 0,
                      label: cardLabel,
                      description: "",
                      pricePerUnit: 0,
                      numberOfUnits: 1,
                      discount: 0,
                      amountToDiscount: 0,
                      lineTotal: 0,
                    },
                  ],
                },
              }),
          /* 
          attachments: ISelectedFile[];
          customFormat?: ISelectedFile;
          quotation?: IQuotationItem;  
          */
        }

        /**
         * For some weird reason, here responsible User property is called userResponsibleBy, but is called responsibleUserBy in services
         */
        if (
          service.userResponsibleBy !== undefined &&
          (selectedUser === undefined ||
            selectedUser !== parseInt(service.userResponsibleBy.id))
        ) {
          setSelectedUser(parseInt(service.userResponsibleBy.id));
        }

        if (
          service.userCreatedBy !== undefined &&
          (selectedUser === undefined ||
            selectedUser !== parseInt(service.userCreatedBy.id))
        ) {
          setSelectedUser(parseInt(service.userCreatedBy.id));
        }

        return cards;
      });
      setServicesArray(cardsArray);
    }
    if (
      (receivedPackages && receivedPackages.length > 0) ||
      (receivedServices && receivedServices.length > 0)
    ) {
      const newItems: Array<ICard> = [];
      // let newPackages: Array<ICard> = [];
      const packagesToAdd: Array<IPackAdded> = [];
      // let newServices: Array<ICard> = [];
      const servicesToAdd: Array<IServAdded> = [];

      const unixDate = moment().unix();

      receivedPackages.map((res: any) => {
        let card: ICard = {
          isCompleted: true,
          type: "package",
          label: `${res.servicePackageSet.servicePackage.servicePackageDesc} - ${res.servicePackageSet.servicePackageType.servicePackageTypeDesc}`,
          serviceValue: "",
          packageValue: res.servicePackageSet.servicePackageCode,
          packageType: res.servicePackageSet.servicePackageTypeCode,
          servicePackageSetId: res.servicePackageSet.servicePackageSetId,
          riskValue: res.riskLevelCode ? res.riskLevelCode : "LOW",
          workshopStaffValue: res.userResponsibleBy
            ? res.userResponsibleBy.id
            : "",
          requiresDiagnosis: res.diagnosticRequired,
          diagnosticMessage: res.details,
          attachments: res.attachments
            ? res.attachments.map(
                (attachment: {
                  fileName: string;
                  photoUrl: string;
                }): ISelectedFile => {
                  return {
                    uid: `${unixDate}-${attachment.fileName}`,
                    name: attachment.fileName,
                    status: "success",
                    photoUrl: attachment.photoUrl,
                  };
                }
              )
            : [],
          ...(res.customFormat
            ? {
                customFormat: {
                  uid: `${unixDate}-${extractFileName(res.customFormat)}`,
                  name: extractFileName(res.customFormat),
                  status: "success",
                  photoUrl: res.customFormat,
                },
              }
            : {}),
          ...(res.quotation
            ? {
                quotation: {
                  id: res.quotation.id,
                  isApproved: res.quotation.isQuotationApproved,
                  lines: res.quotation.packagesLines || [],
                },
              }
            : {
                quotation: {
                  id: 0,
                  isApproved: true,
                  lines: [
                    {
                      id: 0,
                      label: `${res.servicePackageSet.servicePackage.servicePackageDesc} - ${res.servicePackageSet.servicePackageType.servicePackageTypeDesc}`,
                      description: "",
                      pricePerUnit: 0,
                      numberOfUnits: 1,
                      discount: 0,
                      amountToDiscount: 0,
                      lineTotal: 0,
                    },
                  ],
                },
              }),
        };

        // Check first line, to see if we need to create it
        if (
          card.quotation?.lines.length === 0 ||
          card.quotation?.lines[0].pricePerUnit === 0 ||
          card.quotation?.lines[0].pricePerUnit === undefined
        ) {
          const firstLine: IQuotationLine = card.quotation!.lines[0] || {};
          if (firstLine.pricePerUnit === undefined) {
            firstLine.pricePerUnit = 0;
            firstLine.lineTotal = 0;
          }
          if (firstLine.label === undefined || firstLine.label === "")
            firstLine.label = `${res.servicePackageSet.servicePackage.servicePackageDesc} - ${res.servicePackageSet.servicePackageType.servicePackageTypeDesc}`;
          if (firstLine.discount === undefined) {
            firstLine.discount = 0;
            firstLine.amountToDiscount = 0;
          }
          firstLine.numberOfUnits = 1;
          card.quotation!.lines = [firstLine];

          const selectedPackage = packages.filter(
            (current) => current[card.packageValue] !== undefined
          )[0];
          if (selectedPackage !== undefined) {
            const selectedTier = selectedPackage[card.packageValue].filter(
              (current) => (current.type = card.packageType)
            )[0];
            if (selectedTier) {
              firstLine.pricePerUnit = selectedTier.price;
              firstLine.lineTotal = selectedTier.price;
            }
          }
        }

        const item: IPackAdded = {
          code: res.servicePackageSet.servicePackageCode,
          type: res.servicePackageSet.servicePackageTypeCode,
        };

        /**
         * For some weird reason, here responsible User property is called userResponsibleBy, but is called responsibleUserBy in services
         */
        if (
          res.userResponsibleBy !== undefined &&
          (selectedUser === undefined ||
            selectedUser !== parseInt(res.userResponsibleBy.id))
        ) {
          setSelectedUser(parseInt(res.userResponsibleBy.id));
        }

        if (
          res.workshopStaffValue !== undefined &&
          (selectedUser === undefined ||
            selectedUser !== parseInt(res.workshopStaffValue))
        ) {
          setSelectedUser(parseInt(res.workshopStaffValue));
        }

        newItems.push(card);
        packagesToAdd.push(item);
      });

      setPackagesAdded(packagesToAdd);

      receivedServices.map((res: any) => {
        const obj: ICard = {
          isCompleted: true,
          type: "services",
          label: `${res.service.serviceName}`,
          serviceValue: res.serviceCode,
          packageValue: "",
          packageType: "",
          servicePackageSetId: 0,
          riskValue: res.riskLevelCode ? res.riskLevelCode : "LOW",
          workshopStaffValue: res.responsibleUserBy
            ? res.responsibleUserBy.id
            : "",
          requiresDiagnosis: res.diagnosticRequired,
          diagnosticMessage: res.details,
          attachments: res.attachments
            ? res.attachments.map(
                (attachment: {
                  fileName: string;
                  photoUrl: string;
                }): ISelectedFile => {
                  return {
                    uid: `${unixDate}-${attachment.fileName}`,
                    name: attachment.fileName,
                    status: "success",
                    photoUrl: attachment.photoUrl,
                  };
                }
              )
            : [],
          ...(res.customFormat
            ? {
                customFormat: {
                  uid: `${unixDate}-${extractFileName(res.customFormat)}`,
                  name: extractFileName(res.customFormat),
                  status: "success",
                  photoUrl: res.customFormat,
                },
              }
            : {}),
          ...(res.quotation
            ? {
                quotation: {
                  id: res.quotation.id,
                  isApproved: res.quotation.isQuotationApproved,
                  lines: res.quotation.servicesLines || [],
                },
              }
            : {
                quotation: {
                  id: 0,
                  isApproved: true,
                  lines: [
                    {
                      id: 0,
                      label: `${res.service.serviceName}`,
                      description: "",
                      pricePerUnit: 0,
                      numberOfUnits: 1,
                      discount: 0,
                      amountToDiscount: 0,
                      lineTotal: 0,
                    },
                  ],
                },
              }),
        };

        // Check first line, to see if we need to create it
        if (
          obj.quotation?.lines?.length === 0 ||
          obj.quotation?.lines[0].label === "" ||
          obj.quotation?.lines[0].label === undefined
        ) {
          const firstLine: IQuotationLine = obj.quotation!.lines[0] || {};
          if (firstLine.pricePerUnit === undefined) {
            firstLine.pricePerUnit = 0;
            firstLine.lineTotal = 0;
          }
          if (firstLine.label === undefined || firstLine.label === "")
            firstLine.label = `${res.service.serviceName}`;
          if (firstLine.discount === undefined) {
            firstLine.discount = 0;
            firstLine.amountToDiscount = 0;
          }
          firstLine.numberOfUnits = 1;
          obj.quotation!.lines = [firstLine];
        }

        let item: IServAdded = {
          code: res.serviceCode,
        };

        /**
         * For some weird reason, here responsible User property is called responsibleUserBy, but is called userResponsibleBy in packages
         */
        if (
          res.responsibleUserBy !== undefined &&
          (selectedUser === undefined ||
            selectedUser !== parseInt(res.responsibleUserBy.id))
        ) {
          setSelectedUser(parseInt(res.responsibleUserBy.id));
        }

        if (
          res.workshopStaffValue !== undefined &&
          (selectedUser === undefined ||
            selectedUser !== parseInt(res.workshopStaffValue))
        ) {
          setSelectedUser(parseInt(res.workshopStaffValue));
        }

        newItems.push(obj);
        servicesToAdd.push(item);
      });

      setServicesArray([...newItems]);
      setServicesAdded(servicesToAdd);
    }
  };

  const createInitialCards = () => {
    const customServices = appointmentInfo.customServices || [];

    const customServiceCards: ICard[] = customServices.map((customService) => {
      const label = [
        customService.workshopServiceName,
        ...(customService.workshopServiceType === "MAN" &&
        Array.isArray(customService.serviceTiers) &&
        customService.serviceTiers[0]
          ? [tierCodeToDesc(customService.serviceTiers[0].servicePackageTypeCode)]
          : []),
      ].join(" - ");

      let price = 0;
      if (
        customService.workshopServiceType === "MAN" &&
        Array.isArray(customService.serviceTiers) &&
        customService.serviceTiers[0]
      ) {
        price = customService.serviceTiers[0].servicePackageTypeCodeBasePrice;
      }
      if (
        customService.workshopServiceType === "REP" &&
        customService.basePrice
      ) {
        price = customService.basePrice;
      }

      return {
        isCustomService: true,
        isCompleted: false,
        type: customService.workshopServiceType,
        //@ts-ignore
        label: label,
        serviceValue: "",
        //@ts-ignore
        packageValue: "",
        //@ts-ignore
        packageType: "",
        //@ts-ignore
        servicePackageSetId: 0,
        //@ts-ignore
        riskValue: "HIGH",
        customServiceId: customService.workshopServiceId,
        customSeviceName: customService.workshopServiceName,
        customServiceType: customService.workshopServiceType,
        customServiceTier:
          customService.workshopServiceType === "MAN" &&
          Array.isArray(customService.serviceTiers) &&
          customService.serviceTiers[0]
            ? (customService.serviceTiers[0])
            : undefined,
        workshopStaffValue: user ? user!.id : "",
        requiresDiagnosis: true,
        diagnosticMessage: "",
        attachments: [],
        quotation: {
          id: 0,
          isApproved: true,
          lines: [
            {
              id: 0,
              description: "",
              label: label,
              pricePerUnit: price,
              discount: 0,
              amountToDiscount: 0,
              lineTotal: price,
              numberOfUnits: 1,
              editableLine: true,
            },
          ],
        },
      };
    });

    const _customServiceAdded: ICustomServiceAdded[] = customServiceCards.map(
      (serviceAdded) => {
        return {
          code: serviceAdded.customServiceId,
          tier: serviceAdded.customServiceTier,
          type: serviceAdded.customServiceType,
        };
      }
    );

    console.info("customServiceCards", customServiceCards);

    setServicesArray(customServiceCards);
    setCustomServiceAdded(_customServiceAdded);
  };

  const clearDrawer = () => {
    setIsLoading(true);
    setIsReadOnly(false);
    // setDiagnosticConfirmation(false);
    setServicesArray([]);
    setPackagesAdded([]);
    setAppointmentTracking(undefined);
    SetCurrentDate(undefined);
  };

  const getAllPackages = () => {
    const packagesResult: Array<IPackTier> = [];
    const ws = workshops.filter(
      (current) => current.id === appointmentInfo?.workshop.id
    )[0];

    const packages = ws.packages.reduce((prev: any, curr: Package) => {
      const array = prev;
      const code = curr.servicePackageSet.servicePackageCode;
      const index = array.findIndex((a: any) => Object.keys(a).includes(code));
      const item = {
        type: curr.servicePackageSet.servicePackageTypeCode,
        typeDesc:
          curr.servicePackageSet.servicePackageType.servicePackageTypeDesc,
        price: curr.price,
        description: curr.servicePackageSet.servicePackageMap,
        comment: curr.comment,
        servicePackageSetId: curr.servicePackageSetId,
        packageName: curr.servicePackageSet.servicePackage.servicePackageDesc,
        warnings: curr.servicePackageSet.servicePackageWarnings,
      };
      if (index < 0) {
        array.push({ [code]: [item] });
      } else {
        const set = { ...array[index] };
        set[code] = [...set[code], item];
        array.splice(index, 1, set);
      }
      // [ {code: package}, {code: packages}]
      return array;
    }, []);

    setPackages(packages);

    if (packages && packages.length > 0) {
      const packagesWithTiers = packages
        .map((_, index: number) => {
          const packInt = packages[index];
          const propKeys = Object.keys(packInt);
          const values: Array<any> = packInt[propKeys[0]];
          const item: IPackWithTiers = {
            packageCode: propKeys[0],
            packageName: "",
            tiers: [],
          };

          if (values && values.length > 0) {
            item.packageName = values[0].packageName;
            item.tiers = values.map((result) => {
              return {
                label: `${result.packageName} - ${result.typeDesc}`,
                packageName: result.packageName,
                packageCode: propKeys[0],
                type: result.type,
                typeDesc: result.typeDesc,
                price: result.price,
                servicePackageSetId: result.servicePackageSetId,
              };
            });
            return item;
          } else {
            return undefined;
          }
        })
        .filter((current) => current !== undefined) as IPackWithTiers[];
      setPackagesWithTiers(packagesWithTiers);
      packages.map((pack: Array<any>, index: number) => {
        const packInt = packages[index];
        const propKeys = Object.keys(packInt);
        const values: Array<any> = packInt[propKeys[0]];

        if (values && values.length > 0) {
          values.map((result) => {
            packagesResult.push({
              label: `${result.packageName} - ${result.typeDesc}`,
              packageName: result.packageName,
              packageCode: propKeys[0],
              type: result.type,
              servicePackageSetId: result.servicePackageSetId,
              typeDesc: result.typeDesc,
              price: result.price,
            });
          });
        }
      });
    } else {
    }

    setPackagesItems(packagesResult);
  };

  const getCoworkers = () => {
    let coworkersArray: Array<ICow> = coworkers.map((res) => {
      return {
        // id: res.id,
        // name: res.name,
        // lastName: res.lastName,
        label: `${res.name} ${res.lastName}`,
        value: res.id,
      };
    });

    setCoworkersItems([...coworkersArray]);
  };

  /**
   *
   * @returns returns true if all cards are ready to be send
   */
  const IsReady = (): boolean => {
    if (selectedUser === undefined) return false;

    if (servicesArray.length === 0) return false;

    if (servicesArray.some((current) => current.isCompleted === false))
      return false;

    return true;
  };

  /**
   * This function prepare all data to be post, and call upload image functions
   * We are adding a security check here, to be sure that all items are valid
   * @returns void
   */
  const sendDiagnose = async () => {
    setExitModal(false);
    setIsLoading(true);

    // Search for inconsistencies in packages
    const invalidServices = servicesArray
      .filter((service) => service.isCustomService)
      .some(
        (service) =>
          service.servicePackageSetId === undefined ||
          (service.customServiceType === "MAN" &&
            service.customServiceTier === undefined)
      );
    if (invalidServices) {
      setIsLoading(false);
      CkMessage({
        type: "error",
        text: "Ocurrió un error al seleccionar los servicios.",
      });
      return;
    }
    const servicesArrayWithUploadedFiles = await uploadAttachments([
      ...servicesArray,
    ]);

    const payload: IUploadPayload = {
      CustomServices: servicesArrayWithUploadedFiles.map(
        (service: ICard): ICustomServiceQuotationPayload => {
          return {
            CustomServiceSetId: service.customServiceId,
            CustomService: {
              WorkshopServiceId: service.customServiceId,
            },
            riskLevelCode: service.riskValue,
            details: service.requiresDiagnosis ? service.diagnosticMessage : "",
            diagnosticRequired: service.requiresDiagnosis,
            responsibleUser: selectedUser!,
            customFormat:
              service.customFormat && service.customFormat.photoUrl
                ? service.customFormat.photoUrl
                : null,
            attachments: service.attachments.map((file) => {
              return {
                fileName: file.name,
                photoUrl: file.photoUrl || "",
              };
            }),
            Quotation: service.quotation
              ? {
                  id: service.quotation.id,
                  isQuotationApproved: service.quotation.isApproved,
                  customServiceLines: service.quotation.lines,
                }
              : {
                  id: 0,
                  isQuotationApproved: true,
                  customServiceLines: [],
                },
            UserCreatedBy: {
              Id: user.id,
            },
            UserResponsibleBy: {
              Id: selectedUser!.toString(),
            },
          };
        }
      ),
    };

    const requestUpdate = await AppointmentAPI.updateDiagnosticQuotation(
      payload,
      appointmentId
    )
      .then((response) => response.data)
      .catch((err) => false);

    if (requestUpdate === false) {
      CkMessage({
        type: "error",
        text: "Ocurrió un error, intenta más tarde.",
      });
    } else {
      setDiagnosticConfirmation(false);
      setShowForm(false);
      setCompleteProcess(true);
      if (isCalledFromCalendar) handleConfirm("QUOT");
    }
    setIsLoading(false);
  };

  const sendQuotation = async (
    newStatus: "WAITING_APPROVAL" | "APPROVED" = "WAITING_APPROVAL",
    approvalData?: {
      comment?: string;
      imageSupport?: string;
      contactedVia: TContactChannels;
    }
  ) => {
    setIsLoading(true);

    // const services = [...servicesArray].filter((res) => res.type == "services");
    // const packages = [...servicesArray].filter((res) => res.type == "package");

    /**
     * Creating the payload with QuotationStatus `waitingApproval`
     */
    const payload: {
      WorkshopAppointmentTrackingId: number;
      QuotationStatus: TQuotationStatus;
      ServicesQuotation: IServiceQuotationPayload[];
      PackagesQuotation: IPackageQuotationPayload[];
      CustomServiceQuotation: ICustomServiceQuotation[];
      QuotationComment?: string;
      QuotationApprovedByWorkshop?: boolean;
      QuotationUserContactedVia?: string;
      QuotationApprovedByWorkshopSupport?: string;
    } = {
      WorkshopAppointmentTrackingId: appointmentTracking.appointmentTrackingId,
      QuotationStatus: newStatus,
      ServicesQuotation: /* services.map(
        (res: ICard): IServiceQuotationPayload => {
          return res.quotation
            ? {
                Id: res.quotation.id,
                isQuotationApproved: res.quotation.isApproved,
                ServicesLines: res.quotation.lines,
              }
            : {
                Id: 0,
                isQuotationApproved: true,
                ServicesLines: [],
              };
        }
      ) */ [],
      PackagesQuotation: /* packages.map(
        (res: ICard): IPackageQuotationPayload => {
          return res.quotation
            ? {
                Id: res.quotation.id,
                isQuotationApproved: res.quotation.isApproved,
                PackagesLines: res.quotation.lines,
              }
            : {
                Id: 0,
                isQuotationApproved: true,
                PackagesLines: [],
              };
        }
      ) */ [],
      CustomServiceQuotation: servicesArray.map((service: ICard): ICustomServiceQuotation => {
        return service.quotation
          ? {
              id: service.quotation.id,
              isQuotationApproved: service.quotation.isApproved,
              customServiceLines: service.quotation.lines,
            }
          : {
              id: 0,
              isQuotationApproved: true,
              customServiceLines: [],
            };
      }),
      ...(approvalData
        ? {
            QuotationComment: approvalData.comment || null,
            QuotationApprovedByWorkshop: true,
            QuotationUserContactedVia: approvalData.contactedVia,
            QuotationApprovedByWorkshopSupport:
              approvalData.imageSupport || null,
          }
        : {}),
    };

    if (newStatus === "WAITING_APPROVAL") {
      AppointmentAPI.sendQuotationToEndConsumer(payload)
        .then((response) => {
          if (response.data) {
            CkMessage({
              type: "success",
              text: "Cotización enviada al cliente exitosamente.",
            });
            clearDrawer();
            handleConfirm("QUOT");
          } else {
            CkMessage({
              type: "error",
              text: "Ups, ocurrio un problema al enviar la cotización al cliente.",
            });
          }
        })
        .catch(() =>
          CkMessage({
            type: "error",
            text: "Ups, ocurrio un problema al enviar la cotización al cliente.",
          })
        )
        .finally(() => setIsLoading(false));
    } else {
      AppointmentAPI.approveQuotation(payload)
        .then((response) => {
          if (response.data) {
            CkMessage({
              type: "success",
              text: "La cotización fue aprobada exitosamente.",
            });
            handleConfirm("QUOT");
          } else {
            CkMessage({
              type: "error",
              text: "Ups, ocurrio un problema al aprobar la cotización.",
            });
          }
        })
        .catch(() =>
          CkMessage({
            type: "error",
            text: "Ups, ocurrio un problema al aprobar la cotización.",
          })
        );
    }
  };

  const uploadFileAndCompress = async (
    file: RcFile
  ): Promise<TUploadAttachmentOutput> => {
    return new Promise(async (resolve, reject) => {
      let date = new Date();
      let fileName = `diagnosticForm/${appointmentId}/${date.getTime()}_${
        file.name
      }`;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      try {
        new Compressor(file, {
          quality: 0.6,
          convertTypes:
            "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
          convertSize: 3000000,
          async success(result) {
            await blockBlobClient.upload(result, result.size);
            let attached = {
              fileName: file.name,
              photoUrl: blockBlobClient.url.split("?")[0],
            };
            resolve(attached);
          },
        });
      } catch (error) {
        console.info("error compressing and uploading file", error);
        resolve(null);
      }
    });
  };

  const uploadFile = async (file: RcFile): Promise<TUploadAttachmentOutput> => {
    return new Promise(async (resolve, reject) => {
      let date = new Date();
      let fileName = `diagnosticForm/${appointmentId}/${date.getTime()}_${
        file.name
      }`;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      try {
        await blockBlobClient.upload(file, file.size);
        let attached = {
          fileName: file.name,
          photoUrl: blockBlobClient.url.split("?")[0],
        };
        resolve(attached);
      } catch (error) {
        console.info("error uploading file", error);
        resolve(null);
      }
    });
  };

  const uploadAttachments = async (
    servicesArray: ICard[]
  ): Promise<ICard[]> => {
    const processedArray: ICard[] = [];
    await Promise.all(
      servicesArray.map(async (currentCard) => {
        const attachments: ISelectedFile[] = [];
        let customFormat: ISelectedFile | undefined = undefined;

        // Loop through attachments
        await Promise.all(
          currentCard.attachments.map(
            async (file: ISelectedFile, id: number) => {
              if (file.file === undefined) return;

              let uploadRequest: {
                fileName: string;
                photoUrl: string;
              } | null;

              // If is image, compress image and then upload it
              if (file.file.type.toString().startsWith("image/")) {
                uploadRequest = await uploadFileAndCompress(file.file);
              } else {
                // else, just upload it
                uploadRequest = await uploadFile(file.file);
              }
              attachments.push({
                ...file,
                ...uploadRequest,
              });
            }
          )
        );

        if (currentCard.customFormat && currentCard.customFormat.file) {
          const uploadRequest = await uploadFile(currentCard.customFormat.file);
          customFormat = {
            ...currentCard.customFormat,
            ...uploadRequest,
          };
        }

        const processedCard = {
          ...currentCard,
          ...(customFormat ? { customFormat } : {}),
          attachments,
        };
        processedArray.push(processedCard);
      })
    );
    return processedArray;
  };

  const deleteCard = (index: number) => {
    setDeleteModal(true);
    setDeleteIndex(index);
  };

  const questionDeleteCard = (index: number) => {
    const toClear = servicesArray[index];
    const filtered = servicesArray.filter((res, i: number) => i != index);

    if (filtered.length > 0) {
      if (filtered && filtered.length > 0) {
        setServicesArray([...filtered]);
      } else {
        setServicesArray([]);
      }

      if (toClear.type == "services") {
        let filtered = servicesAdded.filter(
          (res) => res.code != toClear.serviceValue
        );
        setServicesAdded(filtered);
      } else {
        let filtered = packagesAdded.filter((res) => {
          let filString: string = `${res.code}${res.type}`;
          let packString: string = `${toClear.packageValue}${toClear.packageType}`;

          if (filString != packString) {
            return true;
          } else {
            return false;
          }
        });
        setPackagesAdded(filtered);
      }

      CkMessage({ type: "success", text: "Tarjeta eliminada" });
    }
  };

  const onClose = () => {
    if (isReadOnly) {
      setDiagnosticConfirmation(false);
      setConfirmCloseModal(false);
      setShowForm(false);
    } else {
      setConfirmCloseModal(true);
    }
  };

  const updateQuotationStatus = (
    newStatus: TQuotationStatus | "FORCE",
    approvalData?: {
      comment?: string;
      imageSupport?: string;
      contactedVia: TContactChannels;
    }
  ) => {
    if (newStatus === "APPROVED" && approvalData) {
      sendQuotation("APPROVED", approvalData);
    }
    return;
  };

  return (
    <Drawer
      className="diagnostic-form-drawer"
      open={diagnosticConfirmation}
      onClose={onClose}
      closeIcon={null}
      zIndex={5000}
      destroyOnClose
      title={null}
    >
      {appointmentTracking === undefined ||
        (appointmentInfo === undefined && (
          <Spin className="saving-spinner filled-background" size="large" />
        ))}

      {appointmentTracking && appointmentInfo && (
        <div className="content-wrapper">
          {isLoading && <Spin className="saving-spinner" size="large" />}
          <HeaderInfo
            appointmentTracking={appointmentTracking}
            appointmentInfo={appointmentInfo}
            currentDate={currentDate}
            onClose={onClose}
          />

          {servicesArray &&
            servicesArray.map((service, index) => (
              <DiagnosticCard
                services={services}
                servicesArray={servicesArray}
                setServicesArray={setServicesArray}
                servicesAdded={servicesAdded}
                setServicesAdded={setServicesAdded}
                packagesAdded={packagesAdded}
                setPackagesAdded={setPackagesAdded}
                packagesItems={packagesItems}
                setPackagesItems={setPackagesItems}
                triggerToClear={triggerToClear}
                setTriggerToClear={setTriggerToClear}
                showForm={showForm}
                setShowForm={setShowForm}
                coworkersItems={coworkersItems}
                isReadOnly={isReadOnly}
                deleteCard={deleteCard}
                data={service}
                index={index}
                canEditDiagnose={canEditDiagnose}
                canEditQuotation={canEditQuotation}
                mode={mode}
              />
            ))}
          <DiagnosticForm
            servicesArray={servicesArray}
            setServicesArray={setServicesArray}
            showForm={showForm}
            setShowForm={setShowForm}
            canEditDiagnose={canEditDiagnose}
            availablePackagesCategories={availablePackagesCategories}
            availableServicesCategories={availableServicesCategories}
            workshopServices={workshopServices}
          />

          {mode === "DIAG" && (
            <TeamMemberSelection
              appointmentInfo={appointmentInfo}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              canEditDiagnose={canEditDiagnose}
            />
          )}

          {(canEditDiagnose || mode === "QUOT") && (
            <QuotationTotals servicesArray={servicesArray} />
          )}

          <div className="complete-diag-container">
            {canEditDiagnose && canEditQuotation && mode === "DIAG" && (
              <CkButton
                variant="primary"
                color="primary"
                disabled={!IsReady()}
                /* onClick={() => sendDiagnose()} */
                onClick={() => setExitModal(true)}
              >
                Enviar a cliente
              </CkButton>
            )}
            {canEditDiagnose === false &&
              canEditQuotation &&
              mode === "QUOT" && (
                <>
                  <CkButton
                    variant="primary"
                    color="primary"
                    disabled={!IsReady()}
                    onClick={() => sendQuotation()}
                  >
                    Enviar a cliente
                  </CkButton>
                  <CkButton
                    variant="secondary"
                    color="primary"
                    disabled={!IsReady()}
                    onClick={() => setShowQuotationApprovalModal(true)}
                  >
                    El cliente confirmó por otro medio
                  </CkButton>
                </>
              )}
            <CkButton
              variant="link"
              color="primary"
              onClick={() => {
                setDiagnosticConfirmation(false);
                // clearDrawer();
              }}
            >
              {((mode === "DIAG" && canEditDiagnose) ||
                (mode === "QUOT" && canEditQuotation)) &&
                "Cancelar"}
              {((mode === "DIAG" && canEditDiagnose === false) ||
                (mode === "QUOT" && canEditQuotation === false)) &&
                "Regresar"}
            </CkButton>
          </div>
        </div>
      )}
      <ConfirmationModal
        zIndex={5001}
        visible={confirmCloseModal}
        onOk={() => {
          setDiagnosticConfirmation(false);
          setConfirmCloseModal(false);
          setShowForm(false);
        }}
        onCancel={() => setConfirmCloseModal(false)}
        title="Advertencia"
        message="Al continuar, se guardaran los cambios, pero no se completará el formulario al momento de cerrarlo. ¿Desea proceder?"
        icon={<CalendarDeleteIcon />}
      />
      <ConfirmationModal
        zIndex={5001}
        visible={exitModal}
        onOk={async () => await sendDiagnose()}
        onCancel={() => setExitModal(false)}
        title="Advertencia"
        message="Al continuar, no podrá agregar o quitar servicios de mantenimiento o reparación. ¿Desea proceder?"
        icon={<CalendarDeleteIcon />}
      />
      <ConfirmDeleteModal
        visible={deleteModal}
        onOk={async () => {
          await questionDeleteCard(deleteIndex);
          setDeleteModal(false);
          setDeleteIndex(0);
        }}
        onCancel={() => {
          setDeleteModal(false);
          setDeleteIndex(0);
        }}
        title="Eliminar servicio"
        message="¿Quieres eliminar este servicio del diagnóstico y cotización que se enviará al cliente?
        Esta acción no se puede revertir."
        okText="Eliminar"
        cancelText="Cancelar"
      />
      <QuotationApprovalModal
        showQuotationApprovalModal={showQuotationApprovalModal}
        setShowQuotationApprovalModal={setShowQuotationApprovalModal}
        updateQuotationStatus={updateQuotationStatus}
        appointmentId={appointmentInfo ? appointmentInfo.id : ""}
      />
    </Drawer>
  );
};

export default memo(DiagnosticFormDrawer);
