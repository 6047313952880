import React, { FC, memo } from "react";
import { Button, Image } from "antd";
import { useSelector } from "react-redux";

import { getSasToken } from "../../../../../../stores";
import { TrashIcon } from "../../../../../../assets/SvgIcons";

import { ISelectedFile } from "..";

import "./styles.css";

interface IProps {
  readOnly: boolean;
  fileList: ISelectedFile[] ;
  setFileList: Function;
}
const LocaleAttachmentsMap: FC<IProps> = ({
  fileList,
  setFileList,
  readOnly
}) => {
  const sasToken = useSelector(getSasToken);
  
  const IsImage = (url: string) => {
    const fileTypes: any[] = ["png", "jpg", "jpeg"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsVideo = (url: string) => {
    const fileTypes: any[] = ["mp4", "avi", "flv", "wmv", "mov", "hvec"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsAudio = (url: string) => {
    const fileTypes: any[] = [
      "mp3",
      "ogg",
      "wav",
      "wave",
      "wma",
      "oga",
      "mogg",
      "aac",
      "m4a",
      "3gp",
      "m4r",
    ];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const FilePreview: FC<{
    readOnly?: boolean;
    file: ISelectedFile;
    fileType: "image" | "video" | "audio";
  }> = ({ readOnly, file, fileType }) => {
    const deleteFile = () => {
      setFileList((prevState: any[]) => prevState.filter((f: any) => f.uid !== file.uid));
    };
    return (
      <figure className={`file-preview ${fileType}`}>
        {fileType === "image" && (
          <Image
            src={readOnly ? `${file.photoUrl}?${sasToken}` : file.preview}
            preview={true}
          />
        )}
        {fileType === "video" && (
          <video controls>
            <source
              src={readOnly ? `${file.photoUrl}?${sasToken}` : file.preview}
              // type={file.file.type}
            />
            Your browser does not support the video tag.
          </video>
        )}
        {fileType === "audio" && (
          <audio controls>
            <source
              src={readOnly ? `${file.photoUrl}?${sasToken}` : file.preview}
              // type={file.file.type}
            />
            Your browser does not support the video tag.
          </audio>
        )}
        <figcaption>
          {readOnly === false && <Button icon={<TrashIcon />} type="ghost" onClick={deleteFile} />}
        </figcaption>
      </figure>
    );
  };

  if (fileList.length === 0) return null;
  
  return (
    <div className="attachments-wrapper">
      {fileList
        .filter((file: any) => IsImage(readOnly ? file.photoUrl : file.name))
        .map((file: any, idx: number) => (
          <FilePreview
            readOnly={readOnly}
            file={file}
            fileType="image"
            key={`image-preview-${idx}`}
          />
        ))}
      {fileList
        .filter((file: any) => IsVideo(readOnly ? file.photoUrl : file.name))
        .map((file: any, idx: number) => (
          <FilePreview
            readOnly={readOnly}
            file={file}
            fileType="video"
            key={`video-preview-${idx}`}
          />
        ))}
      {fileList
        .filter((file: any) => IsAudio(readOnly ? file.photoUrl : file.name))
        .map((file: any, idx: number) => (
          <FilePreview
            readOnly={readOnly}
            file={file}
            fileType="audio"
            key={`audio-preview-${idx}`}
          />
        ))}
    </div>
  );
};

export default memo(LocaleAttachmentsMap);
